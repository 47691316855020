export const strings = {
  en: {
    ctaTitle: () => `Choose your impact`,
    ctaMessage: () => `At no extra cost, 1% of every order is donated to a nonprofit you choose. Powered by Beam`,
    inlineSeparator: () => `:`,
  },
  fr: {
    ctaTitle: () => `Choisissez l'impact de votre choix`,
    ctaMessage: () =>
      `Sans frais supplémentaires, 1% de chaque commande est reversé à l'organisation caritative de votre choix. Développé par Beam`,
    inlineSeparator: () => `:`,
  },
  es: {
    ctaTitle: () => `Elige el impacto que deseas`,
    ctaMessage: () =>
      `Sin coste adicional, el 1% de cada pedido se dona a la organización sin ánimo de lucro que tú elijas. Desarrollado por Beam`,
    inlineSeparator: () => `:`,
  },
  de: {
    ctaTitle: () => `Wählen Sie Ihren Einfluss`,
    ctaMessage: () =>
      `Ohne zusätzliche Kosten wird 1 % jeder Bestellung an eine von Ihnen gewählte gemeinnützige Organisation gespendet. Von Beam unterstützt`,
    inlineSeparator: () => `:`,
  },
  it: {
    ctaTitle: () => `Scegliete chi sostenere`,
    ctaMessage: () =>
      `L'1% di ogni vostro acquisto viene donato, senza alcun costo aggiuntivo, a un'organizzazione no-profit a libera scelta. Promosso da Beam`,
    inlineSeparator: () => `:`,
  },
  pl: {
    ctaTitle: () => `Masz wpływ, dokonaj wyboru`,
    ctaMessage: () =>
      `1% kwoty każdego zamówienia jest przekazywane na rzecz wybranej przez Ciebie organizacji non-profit – bez dodatkowych kosztów. Obsługiwane przez Beam`,
    inlineSeparator: () => `:`,
  },
};
